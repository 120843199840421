import { Optional, requireNotNull } from 'shared/util/types.util'
import { isEmpty } from 'lodash'

export function filterMap<T, R>(array: T[], mapper: (val: T, index: number) => Optional<R>): R[] {
  return filterNullAndUndefined(array.map(mapper))
}

export function findMapped<T, R>(array: T[], mapper: (val: T, index: number) => Optional<R>): R | undefined {
  for (let i = 0; i < array.length; i += 1) {
    const result = mapper(array[i], i)
    if (result != null) {
      return result
    }
  }
  return undefined
}

export function filterNullAndUndefined<T>(array: Optional<T>[]): T[] {
  return array.filter((val) => val != null) as T[]
}

export function findRequired<T>(array: T[], predicate: (val: T) => boolean): T {
  return requireNotNull(array.find(predicate), 'Could not find required element')
}

export function firstIfOneElement<T>(array: T[]): T | undefined {
  return array.length === 1 ? array[0] : undefined
}

export function moveItemInArray<T>(array: T[], from: number, to: number): T[] {
  const result = [...array]
  const startIndex = to < 0 ? result.length + to : to
  const item = result.splice(from, 1)[0]
  result.splice(startIndex, 0, item)
  return result
}

export function isArray<T>(items: Optional<T[]> | boolean): items is T[] {
  return Array.isArray(items)
}

export function isNotEmpty<T>(arr: T[] | undefined | null): arr is T[] {
  return !isEmpty(arr)
}

export function arrayWithout<T>(items: T[], item: T): T[] {
  return items.filter((i) => i !== item)
}

export function reverseArray<T>(items: T[]): T[] {
  return items.map((_, index) => items[items.length - 1 - index])
}
