import { DateTime } from 'luxon'
import { AdviceStatus } from 'interfaces/Advice'
import AdviceRole from 'shared/models/AdviceRole.model'
import BusinessProcess from 'shared/models/BusinessProcess'
import Dock from 'shared/models/Dock.model'
import Shipment from 'shared/models/Shipment.model'
import Cargo from 'shared/models/cargo.model'
import AttachedDocument from 'shared/models/AttachedDocument.model'
import TimeDeviation from 'shared/models/TimeDeviation.model'
import AdviceSearchHighlight from 'shared/models/search_highlights/AdviceSearchHighlight.model'
import { OperatorAdvicePermissions } from 'components_sites_calendar/store/interfaces/eventTypes'
import { Identified } from 'shared/util/types.util'
import { ID } from 'interfaces/generalTypes'
import { Driver } from 'shared/models/Driver'

export { AdviceStatus }

export enum AdviceType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum AdviceCategory {
  SLOT = 'slot',
  ANNOUNCEMENT = 'announcement',
  SPECIAL_REQUEST = 'special_request',
}

export enum AdviceTag {
  ANNOUNCED = 'announced',
  UNANNOUNCED = 'unannounced',
  MULTIPLE_SUPPLIERS = 'multiple-suppliers',
  MULTIPLE_CUSTOMERS = 'multiple-customers',
  GENERATED = 'generated',
  NO_SHOW = 'no-show',
}

export const NO_TRADELINK_ID = ''

export type AdviceId = number
export type TradelinkId = string

export interface MinimalAdvice extends Identified {
  tradelinkId: TradelinkId
  siteId: ID
}

export const NO_PERMISSIONS: OperatorAdvicePermissions = { edit: false }

export default interface Advice {
  id?: AdviceId
  referenceNumber?: string
  tradelinkId?: TradelinkId
  adviceRoles: AdviceRole[]
  businessProcesses: BusinessProcess[]
  vehicleTractorLicensePlate?: string
  vehicleTrailerLicensePlate?: string
  adviceType: AdviceType
  vehicleType?: string
  preferredWindowStart?: DateTime
  preferredWindowEnd?: DateTime
  title: string
  dockName?: string
  tags: string[]
  status?: AdviceStatus
  internalNote?: string
  comment?: string
  permissions?: OperatorAdvicePermissions
  dock?: Dock
  shipments: Shipment[]
  cargoes: Cargo[]
  documents: AttachedDocument[]
  createdAt?: DateTime
  timeDeviation?: TimeDeviation
  siteId: number
  delayed?: boolean
  searchHighlights?: AdviceSearchHighlight
  driver?: Driver
}

export function buildEmptyAdvice(): Partial<Advice> {
  return {
    adviceRoles: [],
    businessProcesses: [],
    tags: [],
    shipments: [],
    cargoes: [],
  }
}
